<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      width="375px"
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      @click:outside="cancel"
    >
      <v-container class="pa-0">
        <v-card max-width="375" class="mx-auto">
          <v-img :src="event.logo_byte" height="200px" dark></v-img>
          <v-list two-line>
            <div>
              <div class="field text-center">
                <p class="control">
                  <a
                    id="rsvp_event"
                    :href="
                      (!empty(event.custom_domain)
                        ? event.custom_domain
                        : 'https://eventsmv.online') +
                      '/rsvp/' +
                      event.id +
                      '/' +
                      event.slug
                    "
                    target="_blank"
                    class="button download-button"
                    style=""
                  >
                    <span>RSVP the Event</span>
                  </a>
                </p>
              </div>
            </div>
            <v-list-item>
              <v-list-item-content
                v-html="event.intro_text"
              ></v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo">mdi-calendar</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ formatDate(event.from_date, "MMM D, yyyy") }}
                  to
                  {{ formatDate(event.to_date, "MMM D, yyyy") }}
                </v-list-item-title>
                <v-list-item-subtitle>Dates</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item v-if="!empty(event.tel)">
              <v-list-item-icon>
                <v-icon color="indigo">mdi-phone</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ event.tel }}
                </v-list-item-title>
                <v-list-item-subtitle>Tel</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="!empty(event.hot_line)">
              <v-list-item-action></v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ event.hot_line }}
                </v-list-item-title>
                <v-list-item-subtitle>Hot Line</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item v-if="!empty(event.email)">
              <v-list-item-icon>
                <v-icon color="indigo">mdi-email</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <a
                    :href="'mailto:' + event.email + '?subject=EventsMv.online'"
                    target="_blank"
                    style="text-decoration: none"
                  >
                    {{ event.email }}
                  </a>
                </v-list-item-title>
                <v-list-item-subtitle>Email</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item v-if="!empty(event.event_website)">
              <v-list-item-icon>
                <v-icon color="indigo">mdi-web</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <a
                    :href="event.event_website"
                    target="_blank"
                    style="text-decoration: none"
                  >
                    {{ event.event_website }}
                  </a>
                </v-list-item-title>
                <v-list-item-subtitle>Event Website</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item v-if="!empty(event.location)">
              <v-list-item-icon>
                <v-icon color="indigo">mdi-map-marker</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ event.location }}
                </v-list-item-title>
                <v-list-item-subtitle>Location</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item v-if="!empty(event.client)">
              <v-list-item-icon>
                <v-img
                  :src="imUrl + event.client_logo"
                  contain
                  width="50px"
                  dark
                ></v-img>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ event.client }}
                </v-list-item-title>
                <v-list-item-subtitle>Hosted By</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <div>
              <div class="field text-center">
                <p class="control">
                  <a
                    id="become_exhibitor"
                    :href="
                      (!empty(event.custom_domain)
                        ? event.custom_domain
                        : 'https://eventsmv.online') +
                      '/exhibitor/' +
                      event.id +
                      '/' +
                      event.slug
                    "
                    target="_blank"
                    class="button download-button"
                    style="
                      background-color: red !important;
                      color: white !important;
                    "
                  >
                    <span>Become an Exhibitor</span>
                  </a>
                </p>
              </div>
            </div>
          </v-list>
        </v-card>
      </v-container>
    </v-dialog>
  </v-row>
</template>
<script>
import Vue from "vue";
import { Button, Form, FormItem, Input } from "element-ui";
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Input);
export default {
  created() {},
  watch: {
    show: function () {
      this.showForm = this.show;
    },
  },
  props: {
    event: null,
    show: {
      type: Boolean,
      default: false,
    },
  },
  newd() {},
  components: {},
  data() {
    return {
      loading: false,
      showForm: false,
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
  },
  computed: {},
  name: "EventViewScreen",
};
</script>
