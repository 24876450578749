<template>
  <div class="pa-3">
    <v-card class="pb-10" :elevation="0">
      <v-card-text>
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-12">
            <v-card class="mb-10" :elevation="0">
              <v-card-text>
                <v-row class="fill-height">
                  <v-col>
                    <v-sheet height="64">
                      <v-toolbar flat>
                        <v-btn
                          outlined
                          class="mr-4"
                          color="grey darken-2"
                          @click="setToday"
                        >
                          Today
                        </v-btn>
                        <v-btn
                          fab
                          text
                          small
                          color="grey darken-2"
                          @click="prev"
                        >
                          <v-icon small>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn
                          fab
                          text
                          small
                          color="grey darken-2"
                          @click="next"
                        >
                          <v-icon small>mdi-chevron-right</v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">
                          {{ $refs.calendar.title }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          color="grey darken-2"
                          class="mr-1"
                          @click="prev"
                        >
                          <v-icon small>mdi-chevron-left</v-icon>
                          <span>Previous Month</span>
                        </v-btn>
                        <v-btn
                          outlined
                          color="grey darken-2"
                          class="ml-1"
                          @click="next"
                        >
                          <v-icon small>mdi-chevron-right</v-icon>
                          <span>Next Month</span>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-menu bottom right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              outlined
                              color="grey darken-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span>{{ typeToLabel[type] }}</span>
                              <v-icon right>mdi-menu-down</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="type = 'day'">
                              <v-list-item-title>Day</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                              <v-list-item-title>Week</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                              <v-list-item-title>Month</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = '4day'">
                              <v-list-item-title>4 days</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-toolbar>
                    </v-sheet>
                    <v-sheet height="600">
                      <v-calendar
                        ref="calendar"
                        v-model="focus"
                        color="primary"
                        event-end="from_date"
                        event-start="to_date"
                        :events="events"
                        :type="type"
                        @click:event="showEvent"
                        @click:more="viewDay"
                        @click:date="viewDay"
                        @change="getEvents"
                      ></v-calendar>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <v-card class="pb-10" :elevation="0">
              <v-system-bar height="47" color="#aeddff">
                <h3 class="black--text">Upcoming</h3>
                <v-spacer />
                <v-btn small color="primary" @click="exhibitorLogin">
                  EXHIBITOR LOGIN
                  <v-icon small class="ml-2 white--text" color="whute">
                    mdi-key-outline
                  </v-icon>
                </v-btn>
              </v-system-bar>
              <v-card-text>
                <template v-if="up_coming.length > 0">
                  <v-list
                    three-line
                    v-for="item in up_coming"
                    :key="'list-' + item.id"
                  >
                    <v-list-item :selectable="false">
                      <v-list-item-avatar>
                        <v-img :src="item.logo_byte"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.name"
                        ></v-list-item-title>
                        <v-chip
                          class="ma-2"
                          color="primary"
                          label
                          outlined
                          style="max-width: 220px"
                        >
                          <v-list-item-subtitle
                            v-html="
                              formatDate(item.from_date, 'MMM D, yyyy') +
                              ' to ' +
                              formatDate(item.to_date, 'MMM D, yyyy')
                            "
                          ></v-list-item-subtitle>
                        </v-chip>
                        <v-list-item-subtitle
                          v-html="item.intro_text"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon
                            color="grey lighten-1"
                            @click="displayEvent(item)"
                          >
                            mdi-information
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="!empty(item.client)">
                      <v-list-item-icon>
                        <v-img
                          :src="imUrl + item.client_logo"
                          contain
                          width="50px"
                          dark
                        ></v-img>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.client }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Hosted By</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                  </v-list>
                </template>
                <div v-else>There are no recentEvents</div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-card-text>
      <!--<v-divider />-->
    </v-card>
    <v-overlay :value="loading" :opacity="0.7">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <event-view
      :show="selectedOpen"
      :event="selectedEvent"
      @cancel="closeEventScreen"
    />
  </div>
</template>
<script>
import Vue from "vue";
import { Button, Calendar } from "element-ui";
Vue.use(Calendar);
Vue.use(Button);
import eventView from "./eventView.vue";
export default {
  created() {
    this.getDashboard();
  },
  components: {
    eventView,
  },
  data() {
    return {
      up_coming: [],
      events: [],
      loading: false,
      weekday: [1, 2, 3, 4, 5, 6, 0],
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      types: ["month", "week", "day", "4day"],
      dutys: [],
      duty_start_date: new Date(),
      duty_end_date: new Date(),
      focus: "",
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    };
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    displayEvent(event) {
      const open = () => {
        this.selectedEvent = event;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    closeEventScreen() {
      this.selectedOpen = false;
    },
    getEvents() {},
    exhibitorLogin() {
      document.location.href = "/sign-in";
    },
    getDashboard() {
      this.loading = true;
      this.api("dashboard")
        .then((response) => {
          if (response.status == true) {
            this.loading = false;
            this.events = [];
            let events = response.events;
            events.forEach((el) => {
              el.from_date = new Date(el.from_date);
              el.to_date = new Date(el.to_date);
              this.events.push(el);
            });
            this.up_coming = response.up_coming;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {},
  name: "PublicHome",
};
</script>
